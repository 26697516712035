<template>
	<div id="transactions-container">
		<table class="transactions" v-if="transactions.length > 0">
			<tr class="header-row">
				<th>{{ languageStrings.casinoId }}</th>
				<th>{{ languageStrings.fromPlayerCash }}</th>
				<th>{{ languageStrings.status }}</th>
				<th>{{ languageStrings.initiationDate }}</th>
				<th>{{ languageStrings.offerExpirationDate }}</th>
				<th>{{ languageStrings.provider }}</th>
				<th>{{ languageStrings.statusAdditionalInfo }}</th>
			</tr>
			<tr class="data-row" v-for="(item, index) in transactions" :key="index">
				<td>{{ item.casinoId }}</td>
				<td>{{ systemCurrencyTool.formatCurrency(item.fromPlayerCashCOC, systemCurrencyTool.displayType.minorOrFull) }}</td>
				<td>{{ item.status }}</td>
				<td v-if="item.initiationDate">{{ new Date(item.initiationDate).toLocaleTimeString(this.localeString, dateOptions) }}</td>
				<td v-else></td>
				<td v-if="item.offerExpirationDate">{{ new Date(item.offerExpirationDate).toLocaleTimeString(this.localeString, dateOptions) }}</td>
				<td v-else></td>
				<td>{{ item.provider }}</td>
				<td>{{ item.statusAdditionalInfo }}</td>
			</tr>
		</table>
		<h2 v-else>{{ languageStrings.noTransactionsFound }}</h2>
	</div>
</template>

<script>
export default {
	name: "TransactionsTable",
	props: {
		systemCurrencyTool: Object,
		systemSettings: Object,
		transactions: Array,
		languageStrings: Object,
	},
	methods: {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h1,
h2,
h3 {
	text-align: center;
}

#transactions-container {
	display: grid;
	/* margin: auto; */
	/* width: 80%; */
	/* background-color: #d7d7d7; */
	/* border-width: 0 1px 1px 1px;
	border-style: solid;
	box-sizing: border-box; */
}

.transactions {
	position: relative;
	width: 100%;
	margin: auto;
	border-collapse: collapse;
	text-align: center;
	/* background-color: #d7d7d7; */
	box-shadow: 0px 6px 10px 0px rgb(0 0 0 / 50%);
}

.transactions tr {
	transition: background-color 0.3s;
}

tr.header-row {
	position: sticky;
	top: 0;
	background-color: #000;
	color: #fff;
	border-bottom: 1px solid rgb(123, 123, 123);
	white-space: break-spaces;
}

.transactions tr.data-row:hover,
.transactions tr:nth-child(2n):hover {
	background-color: #222;
	color: #fff;
}

.transactions tr:nth-child(2n) {
	background-color: #333;
	color: #fff;
}

.data-row {
	height: 3em;
}

td::first-letter {
    text-transform: capitalize;
}
</style>
