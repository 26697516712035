<template>
	<div id="transactions-table">
		<div v-if="countryCode !== 'MX' && casinoList.length > 1">
			<DropdownMenu id="casino-select" v-if="casinoList?.length > 0" :casinoList="casinoList" />
		</div>
		<div v-if="transactions?.length > 0">
			<div id="paging">
				<label for="limitOptions">{{ languageStrings.limitList }}</label>
				<select :title="languageStrings.setLimit" v-model="limit" id="limitOptions">
					<option v-for="(item, index) in limitOptions" :key="index" :value="item.value">{{ item.text }}</option>
				</select>
				<button :disabled="serverBusy" class="prev-button" type="button" :title="languageStrings.previous" @click="previousPage()">
					{{ languageStrings.previous }}
				</button>
				<button :disabled="serverBusy" class="next-button" type="button" :title="languageStrings.next" @click="nextPage()">{{ languageStrings.next }}</button>
				<span :currentPage="currentPage">{{ languageStrings.page }} {{ currentPage }}</span>
			</div>
			<div>
				<h2>{{ languageStrings.webPayTransactions }}</h2>
				<TransactionsTableMobile
					v-if="isMobile"
					:transactions="transactions"
					:systemCurrencyTool="systemCurrencyTool"
					:languageStrings="languageStrings"
				/>
				<TransactionsTable
					v-if="!isMobile"
					:transactions="transactions"
					:systemCurrencyTool="systemCurrencyTool"
					:languageStrings="languageStrings"
				/>
			</div>
		</div>
		<h2 v-else>{{ languageStrings.noTransactionsFound }}</h2>
	</div>
</template>

<script>
import { onBeforeUnmount } from "vue";
import sharedScripts from "@/dependencies/sharedScripts";
import DropdownMenu from "@/components/DropdownMenu.vue";
import TransactionsTableMobile from "@/components/TransactionsTableMobile.vue";
import TransactionsTable from "@/components/TransactionsTable.vue";

export default {
	//TransactionsTableMobile
	name: "WebPayTransactions",
	inheritAttrs: false,
	props: {
		casinoList: Array,
		playerState: Object,
		isMobile: Boolean,
		systemCurrencyTool: Object,
		languageStrings: Object,
		languageErrorStrings: Object,
	},
	components: {
		DropdownMenu,
		TransactionsTableMobile,
		TransactionsTable,
	},
	data() {
		return {
			status: Object.assign({}, this.globalStatus),
			limitOptions: [
				{ text: "5", value: 5 },
				{ text: "10", value: 10 },
				{ text: "20", value: 20 },
				{ text: "50", value: 50 },
				{ text: "100", value: 100 },
				{ text: "250", value: 250 },
				{ text: "500", value: 500 },
			],
			currentPage: 1,
			offset: 0,
			limit: 20,
			isLastPage: false,
			transactions: [],
			casinoSelected: {},
			serverBusy: false,
			busyText: "",
		};
	},
	watch: {
		casinoSelected() {
			this.currentPage = 1;
			this.offset = 0;
			this.getTransactions();
		},
		limit() {
			this.currentPage = 1;
			this.offset = 0;
			this.getTransactions();
		},
	},
	created() {
		this.getTransactions();
		this.eventBus.on("casinoSelected", (payload) => {
			this.casinoSelected = payload;
			this.getTransactions();
		});
		onBeforeUnmount(() => {
			this.eventBus.off("casinoSelected");
		});
	},
	mounted() {
		if (this.countryCode === "MX" || this.casinoList.length === 1) this.eventBus.emit("casinoSelected", this.pickDefaultCasino());
	},
	methods: {
		pickDefaultCasino() {
			let casinoIdSelected;
			casinoIdSelected = this.casinoList.filter((casino) => casino.isEnabled === true)[0];
			return casinoIdSelected;
		},
		async getTransactions() {
			this.serverBusy = true;
			this.busyText = this.languageStrings.gettingTransaction;
			// Check if session needs to be refreshed
			let success = await this.authenticationCheck(this);
			if (success.hasOwnProperty("ok") && !success.ok) {
				this.serverBusy = false;
				this.busyText = "";
				return false;
			}

			let headerObj = new Headers();
			headerObj.append("Authorization", `Bearer ${this.playerState.accessToken}`);
			headerObj.append("Content-Type", "application/json; charset=utf-8");
			let requestUrl = new URL(`/api/v1/webpay/transaction`, this.rabbitsfootHostUrl);
			let params = requestUrl.searchParams;

			// get one more item than requested to see if a second page exists
			let currentLimit = this.limit + 1;
			if (currentLimit) params.set("limit", currentLimit);
			if (this.casinoSelected?.id) params.set("casinoId", this.casinoSelected.id);
			params.set("offset", this.offset);
			if (this.playerState.userId) params.set("playerUserId", this.playerState.userId);

			requestUrl.search = params.toString();

			let request = new Request(requestUrl.toString(), {
				method: "GET",
				headers: headerObj,
			});

			try {
				let response = await fetch(request);

				let fetchStatus = sharedScripts.checkFetchErrors(response, this.languageErrorStrings);

				if (fetchStatus && !fetchStatus.ok) {
					this.serverBusy = false;
					this.busyText = "";
					this.eventBus.emit("updateStatus", fetchStatus);
					if (fetchStatus.forceLogout) this.eventBus.emit("forceLogout");
					return false;
				}

				this.transactions = await response.json();

				if (Array.isArray(this.transactions)) {
					this.isLastPage = this.transactions?.length <= this.limit;
					// remove extra item if a next page exists
					if (!this.isLastPage) this.transactions.pop();
				} else {
					this.status.message = ` ${this.languageErrorStrings.serverReturnedCode }  ${this.transactions.status || this.transactions}  ${this.languageErrorStrings.butDidNotReturnAList}`;
					this.status.ok = false;
					this.eventBus.emit("updateStatus", this.status);
				}
				this.serverBusy = false;
				this.busyText = "";
			} catch (e) {
				this.serverBusy = false;
				this.busyText = "";
				this.status.ok = false;
				this.status.message = e;
				this.eventBus.emit("updateStatus", this.status);
				console.error(e);
			}
		},
		previousPage() {
			if (this.currentPage == 1) return;
			this.currentPage--;
			this.offset = this.offset - this.limit;
			this.getTransactions();
		},
		nextPage() {
			if (this.isLastPage || this.transactions.length < this.limit) return;
			this.offset = this.offset + this.limit;
			this.currentPage++;
			this.getTransactions();
		},
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
	text-align: center;
}

/* #transactions-table {
	width: 95%;
	margin: auto;
} */

#transactions-table {
	width: 95%;
	margin: auto;
	top: 100px;
	position: relative;
	/* z-index: -1; */
}

#transactions-table {
	/* ****** temp fix for a CSS bug I need to understand ****** */
	top: 115px;
}

.transactions tr.data-row:hover,
.transactions tr:nth-child(2n):hover {
	background-color: #222;
	color: #fff;
}

.transactions tr:nth-child(2n) {
	background-color: #333;
	color: #fff;
}

@media (min-width: 768px) {
	#transactions-table {
		width: 80%;
		margin-left: 20%;
		/* display: flex; */
		position: absolute;
		padding-bottom: 75px;
	}
}
</style>
